/**
 * Google Tag Manager utility for SPA
 */

// Push virtual page view to dataLayer
export const pushVirtualPageView = (routeData) => {
  if (window.dataLayer) {
    window.dataLayer.push({
      event: 'virtualPageview',
      virtualPagePath: routeData.path,
      virtualPageTitle: document.title,
      virtualPageName: routeData.name || '',
      virtualPageParams: routeData.params || {}
    });
  }
};

// Initialize GTM with Vue Router
export const initGTM = (router) => {
  // Track initial page view
  pushVirtualPageView({
    path: router.currentRoute.path,
    name: router.currentRoute.name,
    params: router.currentRoute.params
  });

  // Track route changes
  router.afterEach((to) => {
    // Wait for the next tick to make sure the page title is updated
    setTimeout(() => {
      pushVirtualPageView({
        path: to.path,
        name: to.name,
        params: to.params
      });
    }, 100);
  });
}; 